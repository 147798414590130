import "./AuthData.scss"

import React, {useState} from 'react';
import Modal from "../Modal/Modal";
import {useDispatch, useSelector} from "react-redux";
import {AppState} from "../../../store/configureStore";
import {sendCommandToCashRegister} from "../../../shared/cashRegister";
import {authData, activateCashRegister, hideCashRegisterAuthView} from "../../../store/actions/cashRegister";
import logo from "../../../assets/images/amamed-logo-blue.png";
interface IProps {
    show: boolean;
    hide: () => void;
}

const AuthData:React.FC<IProps> = (props: IProps) => {
    const dispatch = useDispatch();
    const usernameDB = useSelector((state: AppState) => state.cashRegister.cashRegisterUsername);
    const passwordDB = useSelector((state: AppState) => state.cashRegister.cashRegisterPassword);
    const activeDB   = useSelector((state: AppState) => state.cashRegister.active);

    const [username, setUsername] = useState(usernameDB);
    const [password, setPassword] = useState(passwordDB);
    const [message, setMessage] = useState('');
    const [error, setError] = useState('');

    return (
        <Modal
            show={props.show}
            modalClosed={props.hide}
        >
            <div className={'modalContainer authDataContainer'} style={{display: 'flex', flexDirection: 'column', marginLeft: 30}}>

                <div className="header">
                    <img src={logo} alt="Logo" />
                    <h4 className="title">
                        Kassensystem
                    </h4>
                </div>

                <p>Sie können bei jeder angenommen Bestellung diese an
                    Ihre Kassensystem übertragen lassen.</p>

                <div className="row">
                    <input
                        type="checkbox"
                        defaultChecked={activeDB}
                        onChange={ (e) => {
                            dispatch(activateCashRegister(e.target.checked))
                        }}
                    />
                    <label>Aktiv</label>
                </div>

                <div className="row">
                    <label>Benutzer/IDF: </label>

                    <input
                        type={'text'}
                        name={'username'}
                        onKeyUp={(e) => {
                            setUsername(e.target.value)
                        }}
                        defaultValue={username}
                    >
                    </input>
                </div>

                <div className="row">
                    <label>Kennwort:</label>
                    <input
                        type={'password'}
                        name={'password'}
                        onKeyUp={(e) => {
                            setPassword(e.target.value)
                        }}
                        defaultValue={password}
                    >
                    </input>
                </div>
                <div className={'messages'}>
                    <div className={'message'}>
                        {message}
                    </div>

                    <div className={'error'}>
                        {error}
                    </div>

                </div>
                <div className={'row actions'}>

                    <button  className={'cancel'} onClick={async () => {
                        dispatch(hideCashRegisterAuthView(false));
                    }} style={{margin: 0, alignSelf: 'flex-end', width: 150}}>cancel
                    </button>
                    <button onClick={async () => {
                        let valid = await sendCommandToCashRegister('identify', username, password);
                        dispatch(authData(username, password));
                        setError('');
                        setMessage('');
                        if (valid) {
                            setMessage('Ihre Anmeldedaten konnten bestätigt werden.')
                            console.log('Ihre Anmeldedaten konnten bestätigt werden.')

                        } else {
                            setError('Ihre Anmeldedaten konnten nicht bestätigt werden')
                            console.log('Ihre Anmeldedaten konnten nicht bestätigt werden')
                        }

                    }} style={{margin: 0, alignSelf: 'flex-end', width: 150}}>Testen
                    </button>

                </div>

            </div>
        </Modal>
    );
};

export default AuthData;
