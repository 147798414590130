import {
    IOrder,
    IOrderItem,
    IOrderMessage,
    IOrderSortMode,
    IStatusOption,
    OrdersInterface,
    TImageStatus
} from "../store/types/Orders";
import * as callSocketApi from "./socketApi";
import {downloadFile} from "./utility";
import {store} from "../store/configureStore";
import * as actions from "../store/actions";
import {
    addPrivateStartEndLine,
    createAesCtrNonce,
    decryptAes, encryptAes,
    privateDecryptFromBase64
} from "./securityHelper";
import {getPrivateKey} from "./csafeSocketApi";

export const getMessageImageTag = (message: IOrderMessage, addBr: boolean): string => {
    let state: OrdersInterface = store.getState().orders;

    if (message.type === 'image' && message.fileToken && state.imagesByFileToken[message.fileToken] && state.imagesByFileToken[message.fileToken].imageData) {
        let result = '';
        if (addBr) {
            result = '<br/>';
        }
        return result + `<img src='data:image/jpeg;base64,${state.imagesByFileToken[message.fileToken].imageData!}'><br>`;
    }
    return '';
};


export const getStatusOptions = (): (IStatusOption|null)[] => {
    return [
        {type: 20, text: 'Vorbestellung annehmen'},
        {type: 61, text: 'Vorbestellung ablehnen'},
        null,
        {type: 21, text: 'Artikel werden bestellt'},
        {type: 30, text: 'Abholbereit'},
        {type: 40, text: 'Versandbereit'},
        {type: 41, text: 'In Auslieferung'},
        {type: 42, text: 'Ausgeliefert'},
        {type: 50, text: 'Abgeschlossen'},
    ];
};

export const getStatusFilters = (): (IStatusOption)[] => {
    return [
        {type: 1, text: 'Neue Vorbestellung'},
        {type: 20, text: 'Angenommen'},
        {type: 21, text: 'Artikel bestellt'},
        {type: 30, text: 'Bereit zur Abholung'},
        {type: 40, text: 'Bereit zum Versand'},
        {type: 41, text: 'Unterwegs zum Kunden'},
        {type: 42, text: 'Ausgeliefert'},
        {type: 50, text: 'Abgeschlossen'},
        {type: 60, text: 'Abgebrochen durch System'},
        {type: 61, text: 'Abgebrochen durch Apotheke'},
        {type: 62, text: 'Abgebrochen durch Kunde'},
    ];
};

export const getStatusSortings = (): (IOrderSortMode)[] => {
    return [
        {type: 'messageDate', text: 'Letzte Nachricht'},
        {type: 'orderDate', text: 'Letzte Vorbestellung'}
    ];
};

export const getStatusText = (status: number, short: boolean): string => {
    switch (status) {
        case 0:
            return 'Erstellt';
        case 1:
            return 'Vorbestellung empfangen';
        case 20:
            return short ? 'Angenommen' : 'Vorbestellung angenommen';
        case 21:
            return short ? 'Bestellt' : 'Ware bestellt';
        case 30:
            return short ? 'Abholbereit' : 'Abholbereit';
        case 40:
            return short ? 'Versandbereit' : 'Versandbereit';
        case 41:
            return short ? 'Auslieferung' : 'Ware unterwegs';
        case 42:
            return short ? 'Ausgeliefert' : 'Ware ausgeliefert';
        case 50:
            return short ? 'Abgeschlossen' : 'Vorbestellung abgeschlossen';
        case 60:
            return short  ? 'Vorgang abgebrochen' : 'Vorgang durch System abgebrochen';
        case 61:
            return short  ? 'Vorgang abgebrochen' : 'Vorgang durch Apotheke abgebrochen';
        case 62:
            return short  ? 'Vorgang abgebrochen' : 'Vorgang durch Kunde abgebrochen';
    }

    return '';
};

export const getOrderFromData = (data: any, privateKey: any, pharmacyId: any): IOrder|null => {
    let orderId = data.orderId;
    let timestamp = data.timeStamp || data.timestamp;
    let status = data.status;
    let username = '';
    let userAccountId = '';
    let paybackCustomerEan: string | undefined = undefined;
    let paybackCustomerId: string | undefined = undefined;
    let paybackCustomerCardId: string | undefined = undefined;
    let newMessagesCount = 0;
    let sentToPos: boolean | undefined = data.sentToPos;

    let items: IOrderItem[] = [];
    let messages: IOrderMessage[] = [];
    let chatKey: Buffer|null = null;
    let imageStatus: TImageStatus = 'noImages';
    let fromAppId: string | undefined = data.fromAppId;

    if (data.items) {
        for (let i = 0; i < data.items.length; i++) {
            let item = data.items[i];
            let orderItem: IOrderItem = {itemId: item.itemId, type: item.type, quantity: item.quantity};
            let decryptedKey = privateDecryptFromBase64(item.key, privateKey);
            if (orderItem.type === 'text') {
                let decryptedText = decryptAes(decryptedKey, Buffer.from(item.iv, 'base64'), Buffer.from(item.text, 'base64'));
                orderItem.text = decryptedText.toString('utf8');
            } else if (orderItem.type === 'jpg') {
                orderItem.fileToken = item.fileToken;
                orderItem.iv = Buffer.from(item.iv, 'base64');
                orderItem.aesKey = decryptedKey;
                orderItem.imageData = null;
                imageStatus = 'notLoaded';
            } else if (orderItem.type === 'eprescription') {
                let decryptedText = decryptAes(decryptedKey, Buffer.from(item.iv, 'base64'), Buffer.from(item.text, 'base64'));
                orderItem.eprescriptionText = decryptedText.toString('utf8');
            }
            items.push(orderItem);
        }
    }

    if (data.chatKeys) {
        for (let i = 0; i < data.chatKeys.length; i++) {
            let chatKeyData = data.chatKeys[i];
            if (chatKeyData.pharmacyId && chatKeyData.pharmacyId === pharmacyId) {
                chatKey = privateDecryptFromBase64(chatKeyData.key, privateKey);
            }
        }
    }

    if (data.user) {
        if (data.user.name) {
            username = data.user.name;
        }
        if (data.user.userAccountId) {
            userAccountId = data.user.userAccountId;
        }
    }


    let userInfo = data.userInfo;

    if (userInfo && chatKey) {
        if (userInfo.username && userInfo.username.encryptedText && userInfo.username.iv) {
            try {
                username = decryptAes(
                    chatKey,
                    Buffer.from(userInfo.username.iv, 'base64'),
                    Buffer.from(userInfo.username.encryptedText, 'base64')
                ).toString('utf8');
            }
            catch (ex) {
                //
            }
        }

        let payback = userInfo.payback;
        if (payback) {
            let paybackCustomerEanData = payback.paybackCustomerEan;

            if (paybackCustomerEanData && paybackCustomerEanData.encryptedText && paybackCustomerEanData.iv) {
                paybackCustomerEan = decryptAes(
                    chatKey,
                    Buffer.from(paybackCustomerEanData.iv, 'base64'),
                    Buffer.from(paybackCustomerEanData.encryptedText, 'base64')
                ).toString('utf8');
            }

            let paybackCustomerIdData = payback.paybackCustomerId;

            if (paybackCustomerIdData && paybackCustomerIdData.encryptedText && paybackCustomerIdData.iv) {
                paybackCustomerId = decryptAes(
                    chatKey,
                    Buffer.from(paybackCustomerIdData.iv, 'base64'),
                    Buffer.from(paybackCustomerIdData.encryptedText, 'base64')
                ).toString('utf8');
            }

            let paybackCustomerCardIdData = payback.paybackCustomerCardId;

            if (paybackCustomerCardIdData && paybackCustomerCardIdData.encryptedText && paybackCustomerCardIdData.iv) {
                paybackCustomerCardId = decryptAes(
                    chatKey,
                    Buffer.from(paybackCustomerCardIdData.iv, 'base64'),
                    Buffer.from(paybackCustomerCardIdData.encryptedText, 'base64')
                ).toString('utf8');
            }
        }
    }

    //invalid order
    if (!chatKey || username === '' || userAccountId === '') {
        return null;
    }

    if (data.messages) {
        messages = [];
        for (let i = 0; i < data.messages.length; i++) {
            let message = data.messages[i];
            let orderMessage: IOrderMessage = {messageId: message.messageId, type: 'unknown', from: 'system', readByUser: false, readByPharmacy: false};
            if (message.message && typeof message.message.type !== 'undefined') {
                switch (message.message.type) {
                    case 0:
                        orderMessage.type = 'status';
                        orderMessage.status = message.message.value;
                        break;
                    case 1:
                        orderMessage.type = 'text';
                        break;
                    case 2:
                        orderMessage.type = 'image';
                        break;
                }
                switch (message.message.from) {
                    case 0:
                        orderMessage.from = 'system';
                        break;
                    case 1:
                        orderMessage.from = 'pharmacy';
                        break;
                    case 2:
                        orderMessage.from = 'user';
                        break;
                }
                let timestamp = message.timeStamp || message.timestamp;
                if (timestamp) {
                    orderMessage.date = new Date(timestamp);
                }
                if (orderMessage.type === 'text') {
                } else if (orderMessage.type === 'image') {
                    orderMessage.fileToken = message.message.value.fileToken;
                    orderMessage.iv = Buffer.from(message.message.value.iv, 'base64');
                }
                if (message.message.value.iv && message.message.value.textEncrypted) {
                    let decryptedText = decryptAes(
                        chatKey, Buffer.from(message.message.value.iv, 'base64'), Buffer.from(message.message.value.textEncrypted, 'base64')
                    );
                    orderMessage.text = decryptedText.toString('utf8');
                } else if (message.message.value.textUnencrypted) {
                    orderMessage.text = message.message.value.textUnencrypted;
                }

                orderMessage.readByUser = (message.readByUser === 1);
                orderMessage.readByPharmacy = (message.readByPharmacy === 1);

                if (orderMessage.from !== 'pharmacy' && !orderMessage.readByPharmacy) {
                    newMessagesCount++;
                }
            }
            if (typeof orderMessage.type !== 'undefined') {
                messages.push(orderMessage);
            }
        }
    }

    return {
        orderId: orderId,
        timestamp: timestamp,
        date: new Date(timestamp),
        status: status,
        items: items,
        additionalMessages: [],
        messages: messages,
        chatKey: chatKey,
        user: { name: username, userAccountId: userAccountId },
        imageStatus: imageStatus,
        paybackCustomerEan: paybackCustomerEan,
        paybackCustomerId: paybackCustomerId,
        paybackCustomerCardId: paybackCustomerCardId,
        newMessagesCount: newMessagesCount,
        hasImportantMessage: false,
        sentToPos: sentToPos,
        fromAppId: fromAppId
    };
};

export const createAdditionalMessages = (order: IOrder) => {
    let messages: IOrderMessage[] = [];
    if (order.status <= 1) {
        let message: IOrderMessage = {from: 'system', text: 'Möchten Sie die Vorbestellung annehmen?', type: 'question', date: order.date};
        message.answers = [{
            text: 'Nein', priority: false, onClick: () => {
                store.dispatch<any>(actions.sendStatus(order, 61));
                // notificationController.removeNotifications(order.orderId);
            }
        }, {
            text: 'Ja', priority: true, onClick: () => {
                store.dispatch<any>(actions.sendStatus(order, 20));
                // notificationController.removeNotifications(order.orderId);
            }
        }];
        messages.push(message);
    }
    /*if (order.status === 50 && order.paybackCustomerId && order.paybackCustomerId.length === 10) {
        let message: IOrderMessage = {
            from: 'system',
            text: order.user.name +
                ' möchte Payback-Punkte auf seine Karte gutgeschrieben bekommen.\nDie Nummer lautet: ' +
                this.formatPayback(order.paybackCustomerId),
            type: 'question',
            date: order.date
        };
        message.answers = [{
            text: 'Nein', priority: false, onClick: () => {
                //
            }
        }, {
            text: '50 Punkte gutschreiben', priority: true, onClick: () => {
                //
            }
        }];
        messages.push(message);
    }*/
    order.additionalMessages = messages;
    order.hasImportantMessage = (order.additionalMessages.length > 0);
    order.newMessagesCount += order.additionalMessages.length;
    store.dispatch(actions.setAdditionalOrderMessages(order.orderId, messages, order.hasImportantMessage, order.newMessagesCount));
};

export const prepareImagesDownload = (order: IOrder): Promise<void> => {
    return new Promise<void>(async (resolve, reject) => {
        let itemIds: number[] = [];
        for (let i = 0; i < order.items.length; i++) {
            if (order.items[i].type === 'jpg') {
                itemIds.push(order.items[i].itemId);
            }
        }
        let fileTokens = await callSocketApi.prepareFileDownloads( order.orderId, itemIds);
        if (fileTokens.errorCode === 0) {
            for (let i = 0; i < order.items.length; i++) {
                for (let j = 0; j < fileTokens.downloadInfos.length; j++) {
                    let orderItem = order.items[i];
                    if (orderItem.itemId === fileTokens.downloadInfos[j].itemId) {
                        let downloadUrl: string = fileTokens.downloadInfos[j].downloadUrl;
                        orderItem.downloadUrl = downloadUrl;
                        downloadFile(downloadUrl).then((buffer: Buffer) => {
                            if (orderItem.aesKey && orderItem.iv) {
                                let imageData = decryptAes(orderItem.aesKey, orderItem.iv, buffer).toString('base64');
                                store.dispatch(actions.setOrderImageData(order.orderId, orderItem.itemId, imageData));
                            }
                        }).catch(e => console.log('image download failed', e));
                        break;
                    }
                }
            }

            store.dispatch(actions.setOrderData(order));
            resolve();
        } else {
            reject();
        }
    });
};

export const prepareMessageItemDownload = (order: IOrder, message: IOrderMessage): Promise<{downloadUrl: string}> => {
    return new Promise(async (resolve, reject) => {
        let fileTokens = await callSocketApi.prepareMessageItemDownload(message.fileToken);
        if (fileTokens.errorCode === 0) {
            resolve({ downloadUrl: fileTokens.downloadInfos[0].downloadUrl });
        } else {
            reject();
        }
    });
};

export const getMessageText = (message: IOrderMessage): string => {
    if (message.type === 'text' || message.type === 'question') {
        return message.text || '';
    } else if (message.type === 'status') {
        return getStatusText(message.status || 0, false);
    }
    return '';
};

export const getMessageTitle = (message: IOrderMessage, order: IOrder): string => {
    let title = 'System';
    if (message.from === 'user') {
        title = order.user.name;
    } else if (message.from === 'pharmacy') {
        title = store.getState().orders.pharmacyName || 'Apotheke';
    }
    return title;
};

export const loadOrder = (orderId: number): Promise<IOrder> => {
    return new Promise<IOrder>(async(resolve, reject) => {
        let order = await callSocketApi.getSingleOrder( orderId);
        // console.log(order);
        let privateKey = await getPrivateKey();
        if (order.errorCode === 0) {
            let orderData = getOrderFromData(order.orders[0], addPrivateStartEndLine(privateKey), store.getState().auth.pharmacyId);
            console.log(orderData!.chatKey.toString('base64'));
            if (orderData) {
                createAdditionalMessages(orderData);
                resolve(orderData);
            }  else {
                reject();
            }

        } else {
            reject();
        }
    });
};

export const loadThisOrder = (orderId: number) => {

       store.dispatch<any>(actions.loadSingleOrder(orderId));

};

export const sendOrderChatMessage = async (order: IOrder, text: string) => {

        let iv = createAesCtrNonce();
        let encryptedText = encryptAes(order.chatKey, iv, Buffer.from(text, 'utf-8'));
        await callSocketApi.sendMessage(order.orderId, encryptedText.toString('base64'), iv.toString('base64'));

};

export const formatPayback = (paybackNumber: string) => {
    if (paybackNumber.length === 10) {
        return paybackNumber.substr(0, 3) + ' ' + paybackNumber.substr(3, 3) + ' ' + paybackNumber.substr(6, 4);
    }
    return paybackNumber;
};

export const getOrderById = (orderId: number): IOrder|undefined => {
    let state = store.getState();
    if (state.orders && state.orders.allOrders) {
        return state.orders.allOrders[orderId];
    }
    return undefined;
};

export const sendSetOrderSentToPos = async (orderId: number) => {
    let result = await callSocketApi.setSentToPos(
        {
            orderId: orderId,
        }
    );
    console.log('sendSetOrderSentToPos result', result);
    return result;
}

export function getOrderFromAppIdImageRequire(fromAppId?: string) {
    switch (fromAppId) {
        case 'apoHome':
            return require('../assets/images/fromApoHome.png');
        case 'amamed':
            return require('../assets/images/fromAmamed.png');
        case 'gesundDe':
            return require('../assets/images/fromGesundDe.png');
        case 'linda':
            return require('../assets/images/fromLinda.png');
        case 'wepa':
            return require('../assets/images/fromWepa.png');
    }
    return require('../assets/images/fromApoHome.png');
}
