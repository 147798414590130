import * as actionTypes from './actionTypes';
import { OrdersActions } from '../types/ordersActionTypes';
import { Dispatch } from 'redux';

import * as callSocketApi from '../../shared/socketApi';

import {addPrivateStartEndLine, decryptAes} from "../../shared/securityHelper";
import {
    getOrderFromData,
    prepareImagesDownload,
    prepareMessageItemDownload,
    createAdditionalMessages,
    loadOrder,
    sendOrderChatMessage
} from "../../shared/orderHelper";
import {store} from "../configureStore";
import {IOrder, IOrderMessage, TSortMode} from "../types/Orders";
import {downloadFile} from "../../shared/utility";
import {getPrivateKey} from "../../shared/csafeSocketApi";
import {getChatList} from "./chat";


export const initLoadOrder = (): OrdersActions => ({
    type: actionTypes.INIT_LOAD_ORDER
});

export const loadOrderSuccess = (allOrders: any): OrdersActions => ({
    type: actionTypes.LOAD_ORDER_SUCCESS,
    allOrders: allOrders
});

export const loadOrderFail = (error: string): OrdersActions => ({
    type: actionTypes.LOAD_ORDER_FAIL,
    error: error
});

export const getAllOrders = () => {
  return async (dispatch: Dispatch<OrdersActions>) => {
      dispatch(initLoadOrder());
     let orders = await callSocketApi.getOrders();
     let privateKey = await getPrivateKey();
      let orderArray: IOrder[] = [];
      if (orders.errorCode === 0) {
          for (let i = 0; i < orders.orders.length; i++) {
              try {
                  let newOrder = getOrderFromData(orders.orders[i], addPrivateStartEndLine(privateKey), store.getState().auth.pharmacyId);
                  if (newOrder) {
                      createAdditionalMessages(newOrder);
                      orderArray.push(newOrder);
                  }
              }
              catch (ex) {
                  console.log('skip order');
              }
          }
          // console.log(orderArray);
          dispatch(loadOrderSuccess(orderArray));
          dispatch(getChatList() as any);
      } else {
          dispatch(loadOrderFail('Etwas ging schief'));
      }
  };
};

export const getOrdersWithFilter = (statusCode: number, state: any) => {
    return async (dispatch: Dispatch<OrdersActions>) => {
        if (state.loadedOptionalFilters.includes(statusCode)) {
            return;
        }

        let orders = await callSocketApi.getOrdersByStatus([statusCode]);
        let privateKey = await getPrivateKey();
        let filterOrderArray: IOrder[] = [];
        if (orders.errorCode === 0) {
            dispatch(initLoadOrder());
            for (let i = 0; i < orders.orders.length; i++) {
                try {
                    let newOrder = getOrderFromData(orders.orders[i], addPrivateStartEndLine(privateKey), store.getState().auth.pharmacyId);
                    if (newOrder) {
                        createAdditionalMessages(newOrder);
                        filterOrderArray.push(newOrder);
                    }
                }
                catch (ex) {
                    console.log('skip order');
                }
            }
            // console.log(filterOrderArray);
            // add newly retrieved orders
            dispatch(getOrdersWithFiltersSuccess(filterOrderArray, statusCode));

        } else {
            dispatch(loadOrderFail('Etwas ging schief'));
        }
    }
};

export const getOrdersWithFiltersSuccess = (orders: any, statusCode: number): OrdersActions => ({
    type: actionTypes.GET_ORDERS_WITH_FILTERS_SUCCESS,
    orders: orders,
    statusCode: statusCode
});

export const loadSingleOrder = (orderId: number) => {

    return async (dispatch: Dispatch<OrdersActions>) => {
        try {
            let order = await loadOrder(orderId);
            dispatch(singleOrderDataLoaded(order));
        }
        catch (ex) {
            console.log('loadOrder error', ex);
            //TODO: error handling
        }
    };
};

export const singleOrderDataLoaded = (order: IOrder): OrdersActions => ({
    type: actionTypes.SINGLE_ORDER_DATA_LOADED,
    order: order
});

export const setAdditionalOrderMessages = (orderId: number, additionalMessages: IOrderMessage[], hasImportantMessage: boolean, newMessageCount: number): OrdersActions => ({
    type: actionTypes.SET_ADDITIONAL_ORDER_MESSAGES,
    orderId: orderId,
    additionalMessages: additionalMessages,
    hasImportantMessage: hasImportantMessage,
    newMessageCount: newMessageCount
});

export const orderStatusChangeStart = (): OrdersActions => ({
    type: actionTypes.ORDER_STATUS_CHANGE_START
});

export const orderStatusChangeFail = (error: string): OrdersActions => ({
    type: actionTypes.ORDER_STATUS_CHANGE_FAIL,
    orderStatusChangeError: error
});

export const orderStatusChangeSuccess = (orderId: number, status: number): OrdersActions => ({
    type: actionTypes.ORDER_STATUS_CHANGE_SUCCESS,
    orderId: orderId,
    status: status
});

export const sendStatus = (order: IOrder, status: number, dontCreateMessage?: boolean) => {
    return async (dispatch: Dispatch<OrdersActions>) => {
        dispatch(orderStatusChangeStart());


        try {
            console.log(dontCreateMessage);
            await callSocketApi.sendStatus(order.orderId, status, dontCreateMessage);
            dispatch(orderStatusChangeSuccess(order.orderId, status));
        }
        catch (ex) {
            console.log(ex);
            dispatch(orderStatusChangeFail('sendStatus error.'));
        }
    }
};

export const newMessageCountChanged = (): OrdersActions => ({
    type: actionTypes.NEW_MESSAGE_COUNT_CHANGED
});



export const setDontFilterOrderId = (dontFilterOrderId: number): OrdersActions => ({
    type: actionTypes.SET_DONT_FILTER_ORDER_ID,
    dontFilterOrderId: dontFilterOrderId
});

export const setOrderSortMode = (sortMode: TSortMode): OrdersActions => ({
    type: actionTypes.SET_ORDER_SORT_MODE,
    sortMode: sortMode
});

export const setTypedText = (orderId: number, text: string): OrdersActions => ({
    type: actionTypes.SET_TYPED_TEXT,
    orderId: orderId,
    text: text
});

export const showChangeStatus = (orderId?: number, multi?: boolean): OrdersActions => ({
    type: actionTypes.SHOW_CHANGE_STATUS,
    orderId: orderId,
    multi: multi
});

export const hideChangeStatus = () => ({
    type: actionTypes.HIDE_CHANGE_STATUS
});
export const orderSelected = (selectedOrderId: number): OrdersActions => ({
    type: actionTypes.ORDER_SELECTED,
    selectedOrderId: selectedOrderId
});

export const initOrderImagesDownload = (): OrdersActions => ({
    type: actionTypes.INIT_ORDER_IMAGES_DOWNLOAD
});

export const orderImagesDownloadDone = (order: IOrder): OrdersActions => ({
    type: actionTypes.ORDER_IMAGES_DOWNLOAD_DONE,
    order: order
});

export const prepareOrderImagesDownload = (order: IOrder) => {
    return async (dispatch: Dispatch<OrdersActions>) => {
        dispatch(initOrderImagesDownload());
        try {
            await prepareImagesDownload(order);
            dispatch(orderImagesDownloadDone(order));
        } catch (e) {
            console.log('something went wrong ', e);
        }
    }
};

export const setOrderImageData = (orderId: number, orderItemId: number, imageData: string): OrdersActions => ({
    type: actionTypes.SET_ORDER_IMAGE_DATA,
    orderId,
    orderItemId,
    imageData
});

export const setOrderData = (order: IOrder): OrdersActions => ({
   type: actionTypes.SET_ORDER_DATA,
    order
});

export const imageByFileTokenInit = (fileToken: string): OrdersActions => ({
    type: actionTypes.IMAGE_BY_FILE_TOKEN_INIT,
    fileToken
});

export const imageByFileTokenDone = (fileToken: string, imageData?: string): OrdersActions => ({
    type: actionTypes.IMAGE_BY_FILE_TOKEN_DONE,
    fileToken,
    imageData
});

export const imageByFileTokenFailed = (fileToken: string): OrdersActions => ({
   type: actionTypes.IMAGE_BY_FILE_TOKEN_FAILED,
    fileToken
});

export const showLargeImage = (imageData: string): OrdersActions => ({
    type: actionTypes.SHOW_LARGE_IMAGE,
    imageData: imageData
});

export const hideLargeImage = (): OrdersActions => ({
    type: actionTypes.HIDE_LARGE_IMAGE
});

export const loadMessageImage = (order: IOrder, message: IOrderMessage) => {
    if (message.fileToken && message.iv) {
        let fileToken = message.fileToken;
        let iv = message.iv;
        return async (dispatch: Dispatch<OrdersActions>) => {
            dispatch(imageByFileTokenInit(fileToken));
            try {
                let result = await prepareMessageItemDownload(order, message);
                let downloadedFile = await downloadFile(result.downloadUrl);
                let imageData = decryptAes(order.chatKey, iv, downloadedFile).toString('base64');
                dispatch(imageByFileTokenDone(fileToken, imageData));
            } catch (ex) {
                console.log('load failed', ex);
                dispatch(imageByFileTokenFailed(fileToken));
            }

        }
    } else {
        return null;
    }
};

export const sendChatMessage = (order: IOrder, text: string) => {
    return async (dispatch: Dispatch<OrdersActions>) => {
        dispatch(addPharmacyChatMessage(order.orderId, text));
        try {
            await sendOrderChatMessage(order, text);
        } catch (ex) {
            console.log('send order chat message failed');
        }
    }
};

export const messageRead = (order: IOrder, message: IOrderMessage) => {
    if (!message.readByPharmacy) {
        order.newMessagesCount--;
        callSocketApi.sendMessageRead(order, message);
        message.readByPharmacy = true;
    }
    return async (dispatch: Dispatch<OrdersActions>) => {
        dispatch(orderMessageRead(order.orderId, message.messageId!, order.newMessagesCount));
    }
};

export const orderMessageRead = (orderId: number, messageId: number, newMessageCount: number): OrdersActions => ({
    type: actionTypes.ORDER_MESSAGE_READ,
    orderId: orderId,
    messageId: messageId,
    newMessageCount: newMessageCount
});

export const addPharmacyChatMessage = (orderId: number, message: string): OrdersActions => ({
    type: actionTypes.ADD_PHARMACY_CHAT_MESSAGE,
    orderId: orderId,
    message: message
});

export const showFilterStatus = (): OrdersActions => ({
    type: actionTypes.SHOW_FILTER_STATUS
});

export const hideFilterStatus = (): OrdersActions => ({
    type: actionTypes.HIDE_FILTER_STATUS
});

export const toggleActiveOrderFilter = (position: number): OrdersActions => ({
    type: actionTypes.TOGGLE_ACTIVE_ORDER_FILTER,
    position: position
});

export const addOrderForDelete = (orderId: number): OrdersActions => ({
    type: actionTypes.ADD_ORDER_FOR_DELETE,
    orderId: orderId
});

export const removeOrderForDelete = (orderId: number): OrdersActions => ({
    type: actionTypes.REMOVE_ORDER_FOR_DELETE,
    orderId: orderId
});

export const resetMultiSelectedOrderId = (): OrdersActions => ({
    type: actionTypes.RESET_MULTI_SELECTED_ORDER_ID
});

export const deleteThisOrder = (orderId: number) => {
    return async (dispatch: Dispatch<OrdersActions>) => {
        dispatch(orderSelected(-1));
        await callSocketApi.deleteOrder(orderId);
        dispatch(orderDeleted(orderId));
    }
};

export const orderDeleted = (orderId: number): OrdersActions => ({
    type: actionTypes.ORDER_DELETED,
    orderId: orderId
});

export const resetData = () => ({
    type: actionTypes.RESET_DATA
});

