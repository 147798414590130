// System Auth
export const AUTH_START = 'AUTH_START';
export const AUTH_SUCCESS = 'AUTH_SUCCESS';
export const AUTH_FAIL = 'AUTH_FAIL';
export const AUTH_LOGOUT = 'AUTH_LOGOUT';
export const PRIVATE_KEY_FOUND = 'PRIVATE_KEY_FOUND';
export const PRIVATE_KEY_NOT_FOUND = 'PRIVATE_KEY_NOT_FOUND';
export const PRIVATE_KEY_INVALID = 'RSA_KEY_INVALID';
export const PUBLIC_KEY_FOUND = 'PUBLIC_KEY_FOUND';
export const PUBLIC_KEY_NOT_FOUND = 'PUBLIC_KEY_NOT_FOUND';
export const RSA_KEY_LOADING = 'RSA_KEY_LOADING';
export const RSA_KEY_LOADING_DONE = 'RSA_KEY_LOADING_DONE';
export const SHOW_BACKUP_KEY_VIEW = 'SHOW_BACKUP_KEY_VIEW';
export const HIDE_BACKUP_KEY_VIEW = 'HIDE_BACKUP_KEY_VIEW';
export const SHOW_BACKUP_PROMPT_KEY = 'SHOW_BACKUP_PROMPT_KEY';
export const SET_PHARMACY_DATA = 'SET_PHARMACY_DATA';
export const START_CREATE_RSA_KEY = 'START_CREATE_RSA_KEY';
export const END_CREATE_RSA_KEY = 'END_CREATE_RSA_KEY';
export const SAVE_PUSH_NOTIFICATION_TOKEN = 'SAVE_PUSH_NOTIFICATION_TOKEN';
export const SHOW_CSAFE_MODAL = 'SHOW_CSAFE_MODAL';
export const HIDE_CSAFE_MODAL = 'HIDE_CSAFE_MODAL';
export const SAVE_PRIVATE_KEY = 'SAVE_PRIVATE_KEY';

// Orders
export const INIT_LOAD_ORDER = 'INIT_LOAD_ORDER';
export const LOAD_ORDER_SUCCESS = 'LOAD_ORDER_SUCCESS';
export const LOAD_ORDER_FAIL = 'LOAD_ORDER_FAIL';
export const ORDER_SELECTED = 'ORDER_SELECTED';
export const INIT_ORDER_IMAGES_DOWNLOAD = 'INIT_ORDER_IMAGES_DOWNLOAD';
export const ORDER_IMAGES_DOWNLOAD_DONE = 'ORDER_IMAGES_DOWNLOAD_DONE';
export const SET_ORDER_IMAGE_DATA = 'SET_ORDER_IMAGE_DATA';
export const SET_ORDER_DATA = 'SET_ORDER_DATA';
export const IMAGE_BY_FILE_TOKEN_INIT = 'IMAGE_BY_FILE_TOKEN_INIT';
export const IMAGE_BY_FILE_TOKEN_DONE = 'IMAGE_BY_FILE_TOKEN_DONE';
export const IMAGE_BY_FILE_TOKEN_FAILED = 'IMAGE_BY_FILE_TOKEN_FAILED';
export const SET_ADDITIONAL_ORDER_MESSAGES = 'SET_ADDITIONAL_ORDER_MESSAGES';
export const ORDER_STATUS_CHANGE_START = 'ORDER_STATUS_CHANGE_START';
export const ORDER_STATUS_CHANGE_SUCCESS = 'ORDER_STATUS_CHANGE_SUCCESS';
export const ORDER_STATUS_CHANGE_FAIL = 'ORDER_STATUS_CHANGE_FAIL';
export const SET_DONT_FILTER_ORDER_ID = 'SET_DONT_FILTER_ORDER_ID';
export const SET_ORDER_SORT_MODE = 'SET_ORDER_SORT_MODE';
export const SINGLE_ORDER_DATA_LOADED = 'SINGLE_ORDER_DATA_LOADED';
export const SET_TYPED_TEXT = 'SET_TYPED_TEXT';
export const ADD_PHARMACY_CHAT_MESSAGE = 'ADD_PHARMACY_CHAT_MESSAGE';
export const ORDER_MESSAGE_READ = 'ORDER_MESSAGE_READ';
export const NEW_MESSAGE_COUNT_CHANGED = 'NEW_MESSAGE_COUNT_CHANGED';
export const SHOW_CHANGE_STATUS = 'SHOW_CHANGE_STATUS';
export const HIDE_CHANGE_STATUS = 'HIDE_CHANGE_STATUS';
export const SHOW_LARGE_IMAGE = 'SHOW_LARGE_IMAGE';
export const HIDE_LARGE_IMAGE = 'HIDE_LARGE_IMAGE';
export const RESET_DATA = 'RESET_DATA';
export const SHOW_FILTER_STATUS = 'SHOW_FILTER_STATUS';
export const HIDE_FILTER_STATUS = 'HIDE_FILTER_STATUS';
export const TOGGLE_ACTIVE_ORDER_FILTER = 'TOGGLE_ACTIVE_ORDER_FILTER';
export const ADD_ORDER_FOR_DELETE = 'ADD_ORDER_FOR_DELETE';
export const REMOVE_ORDER_FOR_DELETE = 'REMOVE_ORDER_FOR_DELETE';
export const RESET_MULTI_SELECTED_ORDER_ID = 'RESET_MULTI_SELECTED_ORDER_ID';
export const ORDER_DELETED = 'ORDER_DELETED';
export const GET_ORDERS_WITH_FILTERS_SUCCESS = 'GET_ORDERS_WITH_FILTERS_SUCCESS';

// Chat
export const GET_CHAT_LIST_START = 'GET_CHAT_LIST_START';
export const GET_CHAT_LIST_SUCCESS = 'GET_CHAT_LIST_SUCCESS';
export const GET_CHAT_LIST_FAIL = 'GET_CHAT_LIST_FAIL';
export const SET_CHAT_MESSAGES = 'SET_CHAT_MESSAGES';
export const TOGGLE_SHOW_CHAT_VIEW = 'TOGGLE_SHOW_CHAT_VIEW';
export const HIDE_CHAT_VIEW = 'HIDE_CHAT_VIEW';
export const RESET_CHAT_DATA = 'RESET_CHAT_DATA';
export const CHAT_SELECTED = 'CHAT_SELECTED';
export const MESSAGES_LOADING_START = 'MESSAGES_LOADING_START';
export const MESSAGES_LOADING_END = 'MESSAGES_LOADING_END';
export const SET_TYPED_CHAT_TEXT = 'SET_TYPED_CHAT_TEXT';
export const UPDATE_CHAT_MESSAGE_READ_RECEIPTS = 'UPDATE_CHAT_MESSAGE_READ_RECEIPTS';
export const ADD_INCOMING_CHAT_MESSAGE = 'ADD_INCOMING_CHAT_MESSAGE';
export const ADD_CHAT_BADGE_COUNT = 'ADD_CHAT_BADGE_COUNT';
export const RESET_CHAT_BADGE_COUNT = 'RESET_CHAT_BADGE_COUNT';
export const ADD_NEW_CHAT_BUCKET = 'ADD_NEW_CHAT_BUCKET';
export const SET_CHAT_CLOSE = 'SET_CHAT_CLOSE';

//CashRegister
export const AUTH_SAVE = 'AUTH_SAVE';
export const AUTH_SHOW_VIEW = 'AUTH_SHOW_VIEW';
export const AUTH_HIDE_VIEW = 'AUTH_HIDE_VIEW';
export const AUTH_ACTIVATE = 'AUTH_ACTIVATE';
export const AUTH_DEACTIVATE = 'AUTH_DEACTIVATE';

