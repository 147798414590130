import './jsonConnect';
import {
    addPrivateStartEndLine,
    getPublicRSAKeyDer,
    privateDecryptFromBase64,
    randomTransactionId
} from "./securityHelper";
import {loadThisOrder, getOrderById} from "./orderHelper";
import {analyseNewChatMessage, analyseIncomingChatNotification} from "./chatHelper";
import {getPrivateKey} from "./csafeSocketApi";

export const getWssHostUrl = () => {
    let url =  window.location.href.split('#')[0],
        urlParts = url.split('/');
    if (urlParts[2].substring(0,9) === 'localhost') {
        if (urlParts[2].substring(10,15) === '3001') {
            return 'wss://services.apozept.de/wss/pdc/jcConnect';
        } else {
            return 'wss://pdc.dev.apozept.de/wss/pdc/jcConnect';

        }
    } else if (window['wssHost'] !== undefined) {
        return window['wssHost'];
    } else {
        return 'wss://services.apozept.de/wss/pdc/jcConnect';
    }
};

export const getCsafeLoginUrl = () => {
    let url =  window.location.href.split('#')[0],
        urlParts = url.split('/');
    if (urlParts[2].substring(0,9) === 'localhost') {
        if (urlParts[2].substring(10,15) === '3001') {
            return 'https://services.apozept.de/csafe/pharmacyLogin';
        } else {
            return 'https://pdc.dev.apozept.de/csafe/pharmacyLogin';
        }
    } else {
        return 'https://services.apozept.de/csafe/pharmacyLogin';
    }
};

let wssHost = process.env.REACT_APP_BASE_API_URL;
let client;
let connection;
let keepAliveInterval;

export const initWsConnection = () => {
    client = window.getWSClient();
    client.registrateCommand('notification', (json) => {
        if (json.notification && json.notification.orderId) {
            loadThisOrder(json.notification.orderId);
        }
        connection.sendResponse(json.requestId, 0);
    });

    client.registrateCommand('chatNotification', async (json) => {

        if (json.element && json.element.subElements) {
            await analyseNewChatMessage(json);
        }
        if (json.element && !json.element.subElements) {
             analyseIncomingChatNotification(json);
        }
        connection.sendResponse(json.requestId, 0);

    });

    client.onDisconnect = () => {
        if (keepAliveInterval) {
            clearInterval(keepAliveInterval);
            keepAliveInterval = undefined;
        }
        // setTimeout(connect, 1000);
        setTimeout(async () => {
            const loginToken = localStorage.getItem('loginToken');
            if (loginToken) {
                await connect();
                await loginWithToken(loginToken);
            }
        }, 1000);
    }
};

export const loginWithToken = (loginToken) => {
    return new Promise(resolve => {
        connection.sendRequest('loginWithToken', {loginToken: loginToken}, (response, connectionResponse) => {
            resolve(response);
        });
    });
};

export const login = (email, password) => {
    return new Promise(resolve => {
        connection.sendRequest('login', {email: email, password: password}, (response, connectionResponse) => {

            if (response.errorCode === 0) {
                resolve(response);
            } else {
                resolve(null);
            }
        });
    });
};

export const sendKeepAlive = () => {
    connection.sendRequest('keepAlive', {}, (errorCode, response) => {
        // console.log('keepAlive received', errorCode, response);
    });
};

export const connect = () => {
    return new Promise(resolve => {
        connection = client.connectWs(wssHost, (connected) => {

            if (connected) {
                resolve();
                keepAliveInterval = setInterval(sendKeepAlive, 55000);
            }
            else {
                setTimeout(connect, 2000);
            }
        });
    });
};

export const disconnect = () => {
    if (connection) {
        connection.disconnect();
    }
};

export const getOrders = () => {
    return new Promise(resolve => {
        // if we change/update our filter statusCode here we have to change in our 'toggleActiveOrderFilter' reducer
        connection.sendRequest('getOrders', {lastOrderId: 0, status: [1, 20, 21, 30, 40, 41, 42]}, (response, connectionResponse) => {

            resolve(response);
        });
    });
};

export const getOrdersByStatus = (statusCode) => {
    return new Promise(resolve => {
        connection.sendRequest('getOrders', {lastOrderId: 0, status: statusCode}, (response, connectionResponse) => {

            resolve(response);
        });
    })
};

export const deleteOrder = (orderId) => {
    return new Promise(resolve => {
        connection.sendRequest('deleteOrder', {
            orderId: orderId,
            clientTransactionId: randomTransactionId()
        }, (response, connectionResponse) => {

            resolve(response);
        });
    });
};

export const getSingleOrder = (orderId) => {
    return new Promise(resolve => {
        connection.sendRequest('getOrder', {orderId: orderId}, (response, connectionResponse) => {
           resolve(response);
        });
    });
};

export const loginViaVitus = (url, token, kind) => {
    return new Promise(resolve => {
        connection.sendRequest('loginViaVitusAuth',
            {
                endpoint: url,
                authToken: token,
                kind: kind
            }, (response, connectionResponse) => {
           resolve(response);
        });
    });
};

export const prepareFileDownloads = (orderId, itemIds) => {
    return new Promise(resolve => {
        connection.sendRequest('prepareFileDownloads',{orderId: orderId, itemIds: itemIds},(response, connectionResponse) => {

            resolve(response);
        });
    });
};

export const getOwnPharmacyData = () => {
    return new Promise(resolve => {
        connection.sendRequest('getOwnPharmacyData', {}, (response, connectionResponse) => {

           if (response) {
               resolve(response);
           }
        });
    });
};

export const prepareMessageItemDownload = (fileToken) => {
  return new Promise(resolve => {
     connection.sendRequest('prepareMessageItemDownload', {fileTokens: [fileToken]}, (response, connectionResponse) => {
         if (response) {
             resolve(response);
         }
     });
  });
};

export const sendStatus = (orderId, status, dontCreateMessage) => {
    return new Promise(async (resolve, reject) => {
       await connection.sendRequest('setStatus', {
           orderId: orderId,
           status: status,
           clientTransactionId: randomTransactionId(),
           dontCreateMessage: dontCreateMessage
       }, (response, connectionResponse) => {
           if (response) {
               if (status >= 20 && status <= 50) {
                   sendPaybackTransaction(orderId);
               }
               resolve(response);
           }
       });
    });
};

export const sendPaybackTransaction = async (orderId) => {
    let order = getOrderById(orderId);
    if (order && order.paybackCustomerEan) {
        await connection.sendRequest('getSecureTransactionId', {orderId: order.orderId}, async (response, connectionResponse) => {
            if (response) {

                try {
                    if (response.rsaEncryptedSecureTransactionId) {
                        let decryptedId = privateDecryptFromBase64( addPrivateStartEndLine(getPrivateKey()), response.rsaEncryptedSecureTransactionId);
                        await connection.sendRequest('newPaybackTransaction', {
                            orderId: order.orderId,
                            secureTransactionId: decryptedId.toString(),
                            paybackCustomerCardId: order.paybackCustomerCardId
                        }, (response, connectionResponse) => {
                            if (response) {
                                console.log('new payback transaction done');
                            }
                        });
                    }
                } catch (ex) {
                    //
                }
            }
        });

    }
};

export const sendMessage = (orderId, message, iv) => {
    return new Promise(async (resolve, reject) => {
        await connection.sendRequest('sendMessage', {
            orderId: orderId,
            message: message,
            iv: iv,
            type: 1,
            clientTransactionId: randomTransactionId()
        }, (response, connectionResponse) => {
            // console.log(response);
            if (response.errorCode === 0) {
                resolve();
            } else {
                reject();
            }
        });
    });
};

export const sendMessageRead = (order, message) => {
    return new Promise(async (resolve, reject) => {
       await connection.sendRequest('markMessageAsRead', {
           messageIds: [message.messageId]
       }, (response, connectionResponse) => {
           // console.log(response);
          if (response.errorCode === 0) {
              resolve();
          } else {
              reject()
          }
       });
    });
};

export const sendPublicKey = (privateKey) => {
    return new Promise(async(resolve, reject) => {
        await connection.sendRequest('setPublicKey', {publicKey: getPublicRSAKeyDer(privateKey)}, (response, connectionResponse) => {

            if (response.errorCode === 0) {
                resolve();
            } else {
                reject();
            }
        });
    });
};

// ====================================
// ===== CHAT Related methods =========
// ====================================

export const chatGetList = () => {
    return new Promise( (resolve, reject) => {
          connection.sendRequest('chatGetList', {}, (response, connectionResponse) => {

            if (response.errorCode === 0) {
                resolve(response);
            }
        });
    });
};

export const chatGetMessages = (bucketId) => {
    return new Promise((resolve, reject) => {
         connection.sendRequest('chatGetMessages', {bucketId: bucketId}, (response, connectionResponse) => {

            if (response.errorCode === 0) {
                resolve(response);
            } else {
                reject();
            }
        });
    });
};

export const sendNewChatMessage = (newMessage) => {
    return new Promise((resolve, reject) => {
        connection.sendRequest('chatSendMessage', newMessage, (response, connectionResponse) => {

            if (response.errorCode === 0) {
                resolve(response);
            } else {
                reject();
            }
        });
    })
};

export const chatMarkMessage = (data) => {
    return new Promise((resolve, reject) => {
        connection.sendRequest('chatMarkMessage', data, (response, connectionResponse) => {

            if (response.errorCode === 0) {
                resolve(response);
            } else {
                reject();
            }
        });
    })
};

export const getChat = (bucketId) => {
    return new Promise((resolve, reject) => {
        connection.sendRequest('getChat', {bucketId: bucketId}, (response, connectionResponse) => {

            if (response.errorCode === 0) {
                resolve(response);
            } else {
                reject();
            }
        });
    })
};

export const closeChat = (data) => {
    return new Promise((resolve, reject) => {
        connection.sendRequest('chatClose', data, (response, connectionResponse) => {

            if (response.errorCode === 0) {
                resolve(response);
            } else {
                reject();
            }
        });
    })
};

export const setSentToPos = (data) => {
    return new Promise((resolve, reject) => {
        connection.sendRequest('setSentToPos', data, (response, connectionResponse) => {

            if (response.errorCode === 0) {
                resolve(response);
            } else {
                reject();
            }
        });
    })
};
